import React, { useEffect, useRef, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import "./screen.css";
import { DeleteOutlined, DesktopMacOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import { deleteScreen, addScreen, getScreens, screenFlagOff, updateScreen } from "../../Redux/Actions/screenAction";
import { useNavigate } from "react-router-dom";
import TvIcon from '@mui/icons-material/Tv';
import { styled, alpha } from '@mui/material/styles';
import { ReactComponent as AddScreenIcon } from "../../Assets/images/addScreenIcon.svg";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import DeleteModel from "../../components/deletemodel/DeleteModel";
import { getPlaylist } from "../../Redux/Actions/playlistAction";
import { getContent } from "../../Redux/Actions/contentAction";
import ScreenTableComponent from "../../components/screenstable/ScreenTableComponent";
import toast from "react-hot-toast";
import { UPDATE_SCREEN_SUCCESS } from "../../Redux/constant";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: '#15192C',
  fontSize: '14px',
  fontWeight: '700'
}));

function Screen({ socketRef, user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { screens, isScreenAdded, isAdded, isScreenDeleted, mac, screenLoading,type,isUpdated } = useSelector(
    (state) => state.Screen
  );
  const [openModel, setOpenModel] = useState(false);
  const [nMac, setNMac] = useState("");
  const [screenId, setScreenId] = useState({ id: '', mac: '' });
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [orientation, setOrientation] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const macRef = useRef();
  const [textVal, setTextVal] = useState('');
  const [filteredScreen, setFilteredScreen] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog,setOpenEditDialog]=useState(false);
  useEffect(() => {
    // dispatch(getScreens(user._id));
    dispatch(getPlaylist(user?._id));
    dispatch(getScreens(user?._id));
    dispatch(getContent(user?._id))
  }, [])
  useEffect(() => {
    if (isAdded) {
      toast.success('Screen added successfully.')
      socketRef.current?.emit("event:delegate", {
        detail: { mac: macRef.current.value, code },
      });
      setFilteredScreen([...screens]);
      dispatch(screenFlagOff());
    }
    if (isScreenDeleted) {
      setFilteredScreen([...screens]);
      dispatch(screenFlagOff());
    }
    if(isUpdated){
      switch(type){
        case UPDATE_SCREEN_SUCCESS:
          toast.success('Screen updated successfully.');
          socketRef.current?.emit("event:delegate", {
            detail: { mac: macRef.current.value, code },
          });
          dispatch(screenFlagOff());
          break;
          default:
            console.log('invalid case')
      }
    }
  }, [isAdded, isScreenDeleted,type,isUpdated])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const emitOnDeleteScreen = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("screen_deleted", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const deleteScreenHandler = (id, mac) => {
    setScreenId({ id: id, mac: mac })
    setOpenDeleteDialog(true)
  };
  const confirmDeleteScreen = () => {
    dispatch(deleteScreen(screenId.id));
    emitOnDeleteScreen(screenId.mac);
    setOpenDeleteDialog(false);
  }
 const cancelModel=()=>{
  fieldCleaner();
 }
  const fieldCleaner=()=>{
    setCode("");
    setName("");
    setOrientation("");
    setOpenEditDialog(false);
    setOpenModel(false);
  }
  const submit = () => {
    dispatch(addScreen({ name, orientation, user: user?._id, subuser: user?._id, role: user?.role }, code));
   fieldCleaner();
  };
  const editScreenHandler = (id, mac) => {
    const _selectedScreen = screens.find((screen) => screen._id == id);
    setName(_selectedScreen?.name);
    setCode(_selectedScreen?.code);
    setOrientation(_selectedScreen?.orientation);
    setScreenId({ id: id, mac: mac });
    setOpenEditDialog(true);
  };
  const onUpdate = () => {
    dispatch(
      updateScreen(
        {
          name,
          orientation,
        },
        screenId.id
      )
    );
   
    // emitOnAddedScreen();
    fieldCleaner();
  };
  const searchHandler = (e) => {
    setTextVal(e);
    if (e !== '') {
      const searched = screens.filter(s => {
        return Object.values(s)
          .join('')
          .toLocaleLowerCase()
          .includes(e.toLocaleLowerCase())
      })
      setFilteredScreen([...searched])
    }
    else {
      setFilteredScreen([...screens])
    }
  }
  return (
    <>
      <Layout title={"Screen"} user={user}>
        <input ref={macRef} hidden value={mac} />
        <Grid container>
          <Box sx={{
            margin: '0 25px',
            display: 'flex',
            width: '100%',
            borderRadius: '16px',
            // padding: '20px',
            alignItems: 'center',
            // marginLeft: 0
          }}>
            <Grid item lg={8.5} sx={{
              background: 'white',
              padding: '33px 0',
              borderRadius: '16px',
              mr: 2
            }}>
              <PrimarySearchBar
                value={textVal}
                onChange={searchHandler}
              />
            </Grid>
            <Grid item lg={3.5}>
              <div className="button-controlls flex flex-column">
                <button
                  className="add-screen-btn"
                  onClick={() => setOpenModel(true)}
                >
                  +Add Screen
                </button>
                <div className="lower-btn-group flex">
                  <button className="btn-1 main-color">Sync Screens</button>
                  <button className="btn-2 main-color">
                    Update all Screens
                  </button>
                </div>
              </div>
            </Grid>
          </Box>
        </Grid>

        <Grid container>
          <Box sx={{
            margin: '0 25px',
            background: 'white',
            display: 'flex',
            width: '100%',
            borderRadius: '16px',
            alignItems: 'center',
            mt: 4
          }}>
            <div className="screen-table">
              <Table>
                <TableHead>
                  <TableRow style={{ borderBottom: "2px solid #0d0d0d" }}>
                    <CustomTableCell align="center">ID</CustomTableCell>
                    <CustomTableCell align="center">code</CustomTableCell>
                    <CustomTableCell align="center">Name</CustomTableCell>
                    <CustomTableCell align="center">Mac</CustomTableCell>
                    <CustomTableCell align="center">Status</CustomTableCell>
                    <CustomTableCell align="center">Actions</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    screenLoading ?
                      <img
                        src="/images/spiner.gif"
                        style={{ height: "200px", width: "200px" }}
                      />
                      : filteredScreen.length > 0 ?
                        filteredScreen
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((screen, index) => (
                            <>
                              <ScreenTableComponent
                                index={index}
                                screen={screen}
                                deleteScreenHandler={deleteScreenHandler}
                                editScreenHandler={editScreenHandler}
                              />

                            </>
                          ))
                        :
                        screens
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((screen, index) => (
                            <>
                              <ScreenTableComponent
                                index={index}
                                screen={screen}
                                deleteScreenHandler={deleteScreenHandler}
                                editScreenHandler={editScreenHandler}
                              />

                            </>
                          ))
                  }
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                style={{ display: "flex", justifyContent: "space-between" }}
                count={screens.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </div>
          </Box>
        </Grid>


        {/* </div> */}
      </Layout>

      <DeleteModel
        open={openDeleteDialog}
        onClose={(e) => setOpenDeleteDialog(e)}
        onConfirm={confirmDeleteScreen}
      />

      <Dialog
        // maxWidth={"sm"}
        fullWidth={true}
        open={openModel}
        onClose={cancelModel}
      >
        <DialogTitle
          style={{
            textAlign: "center", borderBottom: "2px solid #0d0d0d", fontSize: '18px',
            fontWeight: 700
          }}
        >
          Add Screen
        </DialogTitle>
        <DialogContent>
          <div className="screen-form">
            <div className="icon-screen">
              <AddScreenIcon />
            </div>
            <div className="form-fields">
              <TextField
                value={code}
                onChange={(e) => setCode(e.target.value)}
                type={"text"}
                variant={"standard"}
                label={"Type Screen Code"}
                fullWidth
                sx={{
                  label: {
                    color: 'blue',
                    fontSize: '14px',
                    color: '#92959E',
                  }
                }}
              />
            </div>
            <div className="form-fields">
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                type={"text"}
                variant={"standard"}
                label={"Type Screen Name"}
                fullWidth
                sx={{
                  label: {
                    color: 'blue',
                    fontSize: '14px',
                    color: '#92959E',
                  }
                }}
              />
            </div>
            <div className="form-fields">
              <FormControl fullWidth variant={"standard"}>

                <InputLabel id="orientation-label">Select Orientation</InputLabel>
                <Select
                  labelId="orientation-label"
                  fullWidth
                  onChange={(e) => setOrientation(e.target.value)}
                >

                  <MenuItem value="landscape">Landscape</MenuItem>
                  <MenuItem value="portrait">Portrait</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* <div className="add-user-form-submit-btn">
              <button style={{ marginTop: '40px' }} >Save</button>
            </div> */}

            <Box sx={{
              display: 'flex',
              mt: 8,
              mb: 5,
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <button style={{
                background: 'white',
                padding: '8px',
                width: '130px',
                color: '#0d0d0d',
                border: '1px solid #0d0d0d',
                marginRight: '10px',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
                onClick={cancelModel}>
                Cancel
              </button>
              <button style={{
                background: '#0d0d0d',
                padding: '8px',
                width: '130px',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }} onClick={submit} className="btn primary">
                Save
              </button>

            </Box>

          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        // maxWidth={"sm"}
        fullWidth={true}
        open={openEditDialog}
        onClose={cancelModel}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            borderBottom: "2px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Edit Screen
        </DialogTitle>
        <DialogContent>
          <div className="screen-form">
            <div className="icon-screen">
              <DesktopMacOutlined
                sx={{ fontSize: "100px", color: "var(--primary-color)" }}
              />
            </div>
            <div className="form-fields">
              <TextField
                value={code}
                onChange={(e) => setCode(e.target.value)}
                disabled
                type={"text"}
                variant={"standard"}
                label={"Type Screen Code"}
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
            </div>
            <div className="form-fields">
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                type={"text"}
                variant={"standard"}
                label={"Type Screen Name"}
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
            </div>
            <div className="form-fields">
              <FormControl fullWidth variant={"standard"}>
                <InputLabel id={"orientation"}>Select Orientation</InputLabel>
                <Select
                  id={"orientation"}
                  value={orientation}
                  onChange={(e) => setOrientation(e.target.value)}
                >
                 <MenuItem value="landscape">Landscape</MenuItem>
                  <MenuItem value="portrait">Portrait</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* <div className="add-user-form-submit-btn">
              <button style={{ marginTop: '40px' }} >Save</button>
            </div> */}

            <Box
              sx={{
                display: "flex",
                mt: 8,
                mb: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: 'white',
                  padding: '8px',
                  width: '130px',
                  color: '#0d0d0d',
                  border: '1px solid #0d0d0d',
                  marginRight: '10px',
                  borderRadius: '5px',
                  cursor: 'pointer'
                }}
                onClick={cancelModel}
              >
                Cancel
              </button>
              <button
               style={{
                background: '#0d0d0d',
                padding: '8px',
                width: '130px',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
                onClick={onUpdate}
                className="btn primary"
              >
                Update
              </button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Screen;

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getClientContent } from '../../Redux/Actions/contentAction';
import Image from './component/image';
import Url from './component/url';
import Vedio from './component/vedio';
import './content.css';
function ClientContent({ id }) {
    const dispatch = useDispatch();
    const { clientContent } = useSelector(state => state.Content);
    useEffect(() => {
        dispatch(getClientContent(id));
    }, [id])
    return (
        <div className='content-render'>
            {
                clientContent?.map((item, index) => (
                    <>
                        {
                            item.type === 'image' && <Image key={index} data={item}  />
                        }
                        {
                            item.type === 'video' && <Vedio key={index} data={item}  />
                        }
                        {
                            item.type === 'url' && <Url key={index} data={item}  />
                        }
                    </>
                ))
            }
        </div>
    )
}

export default ClientContent
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getClientPlaylist } from '../../Redux/Actions/playlistAction';
import PlaylistContentCardItem from './component/content-card/PlaylistContentCardItem';
import PlaylistCard from './component/playlist-card/PlaylistCard';
import './playlist.css';
function ClientPlaylist({id}) {
const dispatch=useDispatch();
const {clientPlaylist}=useSelector(state=>state.Playlist)
    useEffect(()=>{
    dispatch(getClientPlaylist(id));
},[id])
  return (
   <>
   <Grid container>
            <div className="playlist-container">
              {
                clientPlaylist.map((play) => (
                  <>
                    <PlaylistCard
                      play={play}
                    //   socketRef={socketRef}
                    //   addContentModel={addContentModel}
                    //   deleteWholePlaylist={deleteWholePlaylist}
                    //   addSchedule={addSchedule}
                    >
                      {play.content.map((v,index) => (
                         <PlaylistContentCardItem 
                         key={index}
                         content={v}
                        //  deleteContent={deleteContent}
                        //  addSchedule={addSchedule}
                        //  updateTimeHandler={updateTimeHandler}
                         playID={play._id}
                         />
                      ))}
                    </PlaylistCard>
                  </>
                ))
              }
            </div>
          </Grid>
   </>
  )
}

export default ClientPlaylist
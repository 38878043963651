import axios from 'axios';

export const baseApi=`${process.env.REACT_APP_ONLINE_URL_API}`;
export const imgUrl=`${process.env.REACT_APP_ONLINE_URL_IMAGE}`;

// export const baseApi=`${process.env.REACT_APP_LOCAL_URL_API}`;
// export const imgUrl=`${process.env.REACT_APP_LOCAL_URL_IMAGE}`

// export const baseApi='https://crisaxscreens.com/api';
// export const imgUrl=`https://crisaxscreens.com`;

export const api = axios.create({
    baseURL:baseApi,
    withCredentials:true,
    // headers:{
    //     'Content-type':['multipart/form-data','application/json'],
    //     Accept:'application/json'
    // }
})
import { Checkbox, TextField, Dialog, DialogContent, DialogTitle, Grid, Typography, InputLabel, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import LinkIcon from "@mui/icons-material/Link";
import AddIcon from '@mui/icons-material/Add';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import SearchIcon from '@mui/icons-material/Search';
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import "./content.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addContent,
  deleteContent,
  checkAllContent,
  unCheckAllContent,
  checkOneContent,
  unCheckOneContent,
  deleteAllContent,
  getContent,
  contentFlagOff,
  deleteContentSchedule,
  addContentSchedule,
  contentUpdate,
} from "../../Redux/Actions/contentAction";
import { PanoramaHorizontalSelectOutlined } from "@mui/icons-material";
import { imgUrl } from "../../api/api";
import Image from "./component/image";
import Vedio from "./component/vedio";
import Url from "./component/url";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import { Box } from "@mui/system";
import { ReactComponent as VideoIcon } from "../../Assets/images/videoIcon.svg";
import { ReactComponent as ImageIcon } from "../../Assets/images/imageIcon.svg";
import { ReactComponent as UrlIcon } from "../../Assets/images/urlIcon.svg";
import DeleteModel from "../../components/deletemodel/DeleteModel";
import toast from "react-hot-toast";
import { getPlaylist } from "../../Redux/Actions/playlistAction";
import { getScreens } from "../../Redux/Actions/screenAction";


function Content({ socketRef, user }) {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { content, isContentAdding, isContentScheduled, isContentScheduledDeleted, isContentLoading, isContentAdded, isDeleted } = useSelector(
    (state) => state.Content
  );
  const [openMediaPop, setOpenMediaPop] = useState(false);
  const [urlContentForm, setUrlContentForm] = useState(false);
  const [urlName, setUrlName] = useState("");
  const [urlContent, setUrlContent] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDeleteId, setContentDeleteId] = useState("");
  const [searchText, setSearchText] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [contentID, setContentID] = useState('');
  const [filteredContent, setFilteredContent] = useState([]);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [day, setDay] = useState([]);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduleInfo, setScheduleInfo] = useState({});
  const [days, setDays] = useState([
    { _id: 1, day: "Mon", dayChecked: false },
    { _id: 2, day: "Tue", dayChecked: false },
    { _id: 3, day: "Wed", dayChecked: false },
    { _id: 4, day: "Thu", dayChecked: false },
    { _id: 5, day: "Fri", dayChecked: false },
    { _id: 6, day: "Sat", dayChecked: false },
    { _id: 7, day: "Sun", dayChecked: false },
  ]);
  const [editContentModel,setEditContentModel]=useState(false);
  const [ids,setIds]=useState('');
  useEffect(() => {
    // dispatch(getContent(user?._id))
    dispatch(getPlaylist(user?._id));
    dispatch(getScreens(user?._id));
    dispatch(getContent(user?._id))
  }, [])
  useEffect(() => {
    if (isContentAdded) {
      setFilteredContent([...content]);
      dispatch(contentFlagOff());
    }
    if (isDeleted) {
      setFilteredContent([...content]);
      dispatch(contentFlagOff());
    }
    if (isContentScheduled) {
      toast.success("Playlist have scheduled");
      setIsScheduled(false);
      // const _checkingScreenAssing = screens.find(
      //   (s) => s.playlist?._id === playlistId
      // );
      // if (_checkingScreenAssing !== undefined) {
      //   emitOnScheduleAdd(_checkingScreenAssing.mac);
      // }
      dispatch(contentFlagOff());
    }
    if (isContentScheduledDeleted) {
      setOpenSchedule(false);
      toast.success("schedule deleted successfully");
      setIsScheduled(false);
      // const __checkingScreenAssing = screens.find(
      //   (s) => s.playlist?._id === playlistId
      // );
      // if (__checkingScreenAssing !== undefined) {
      //   emitOnScheduleAdd(__checkingScreenAssing.mac);
      // }
      dispatch(contentFlagOff());
    }
  }, [isContentAdded, isDeleted, isContentScheduled, isContentScheduledDeleted])
  const handleClick = () => {
    // 👇️ toggle
    setIsActive(current => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };

  function bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(
      Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)),
      10
    );
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  }
  const fileHandler = (e) => {
    const file = e.target.files[0];
    // console.log('from filehandler=>',bytesToSize(file.size), file);
    const form = new FormData();
    form.append("content", file);
    form.append("role", user?.role);
    form.append("userId", user?.role === 'admin' && user?._id);
    form.append("subuser", user?.role === "user" && user?._id);
    dispatch(addContent(form));
    setOpenMediaPop(false);
  };
  const urlContentSubmit = () => {
    const form = new FormData();
    form.append("name", urlName);
    form.append("contenttype", "url");
    form.append("url", urlContent);
    form.append("role", user?.role);
    form.append("userId", user?._id);
    form.append('subuser', user?._id);
    dispatch(addContent(form));
    setOpenMediaPop(false);
  };
  const handleDeleteContent = (id) => {
    console.log(id)
    setOpenDialog(true);
    setContentDeleteId(id);
  };
  const confirmDeleteContent = () => {
    dispatch(deleteContent(contentDeleteId));
    setOpenDialog(false);
  };
  const searchHandler = (e) => {
    setSearchText(e)
    if (e !== '') {
      const searched = content.filter(p => {
        return Object.values(p)
          .join('')
          .toLocaleLowerCase()
          .includes(e.toLocaleLowerCase());
      })
      console.log(searched);
      setFilteredContent([...searched]);
    }
    else {
      setFilteredContent([...content]);
    }
  }

  const addSchedule = (contentid) => {
    const info = content.find((c) => c.id === contentid);
    console.log('from addSchedule=>', info)
    const __startDate = new Date(info?.schedule?.startDate)
      .toLocaleDateString()
      .split("/");
    const __endDate = new Date(info?.schedule?.endDate)
      .toLocaleDateString()
      .split("/");
    const isStartDateOneDigit = [1, 2, 3, 4, 5, 6, 7, 8, 9].find(
      (digit) => digit == __startDate[0]
    );
    const isEndDateOneDigit = [1, 2, 3, 4, 5, 6, 7, 8, 9].find(
      (digit) => digit == __endDate[0]
    );
    const _startDate = `${__startDate[2]}-${isStartDateOneDigit !== undefined
        ? "0" + isStartDateOneDigit
        : __startDate[0]
      }-${__startDate[1]}`.toString();
    const _endDate = `${__endDate[2]}-${isEndDateOneDigit !== undefined ? "0" + isEndDateOneDigit : __endDate[0]
      }-${__endDate[1]}`.toString();
    if (info?.schedule?.scheduled) {
      setIsScheduled(true);
      console.log("inside condition=>", info);
    }
    setContentID(contentid);
    setStartDate(_startDate);
    setEndDate(_endDate);
    setStartTime(info?.schedule?.startTime);
    setEndTime(info?.schedule?.endTime);
    setOpenSchedule(true);
    const dbDay = info.schedule.days;
    // console.log(dbDay)
    const sameArray = days.filter(
      (item) => !dbDay.find((d) => d.day === item.day)
    );
    // console.log([...sameArray,...dbDay]);
    setDays([...sameArray, ...dbDay]);
    setDay([...dbDay]);
  };

  const saveSchedule = () => {
    // console.log(day);
    dispatch(addContentSchedule(contentID, {
      startDate,
      endDate,
      startTime,
      endTime,
      days: day
    }));
    setDay([]);
    setOpenSchedule(false);
  };
  const deleteScheduleFromContent = (id) => {
    dispatch(deleteContentSchedule(id));
  };
  const emitOnScheduleAdd = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("update_duration", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const cancelSchedule = () => {
    setOpenSchedule(false);
    console.log([...days]);
    setDay([]);
    setDays([
      { _id: 1, day: "Mon", dayChecked: false },
      { _id: 2, day: "Tue", dayChecked: false },
      { _id: 3, day: "Wed", dayChecked: false },
      { _id: 4, day: "Thu", dayChecked: false },
      { _id: 5, day: "Fri", dayChecked: false },
      { _id: 6, day: "Sat", dayChecked: false },
      { _id: 7, day: "Sun", dayChecked: false },
    ]);
  };
  const handleDayCheckbox = (e) => {
    const { name, checked, value } = e.target;
    // console.log(name,checked,value,days)
    if (checked) {
      setDay((prev) => [...prev, { day: value, dayChecked: true }]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: true };
      setDays([...days]);
      // console.log(selectedDayIndex)
    } else {
      setDay((prev) => [...day.filter((d) => d.day !== value)]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: false };
      // console.log(selectedDayIndex)
      setDays([...days]);
    }
    // console.log('days array=>',days)
  };
  const onEditContent=(_contentData)=>{
    //  console.log(_contentData)
    setIds(_contentData.id);
     setUrlName(_contentData.name);
     setUrlContent(_contentData.url);
     setEditContentModel(true);
  }
  const urlContentUpdate=()=>{
    dispatch(contentUpdate(ids,{url:urlContent,name:urlName}));
    setEditContentModel(false);
  }
  return (
    <Layout title="Content" user={user}>
      <div className="parent-container">

        <div className="child-container">
          <Grid container>
            <Box
              sx={{
                background: "white",
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Grid lg={8}>
                <Box
                  sx={{
                    background: "white",
                    padding: "33px 0",
                    marginRight: "20px !important",
                    borderRadius: "16px",
                  }}
                >
                  <PrimarySearchBar
                    value={searchText}
                    onChange={searchHandler}
                  />
                </Box>
              </Grid>
              <Grid lg={4} display={"flex"} justifyContent={"flex-end"}>
                <div className="user-add-btn">
                  <button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#0d0d0d" }} onClick={() => setOpenMediaPop(true)}><AddIcon /> Add Content</button>
                </div>
              </Grid>
            </Box>
          </Grid>
        </div>
        {
          isContentAdding && <>
            <Box sx={{
              position: 'absolute',
              height: '100vh',
              width: '100vw',
              //  background:'rgba(0,0,0,0.5)',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: '1000000',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <img src="/images/spiner.gif" />
            </Box>
          </>
        }

        <div className="content-render">
          {
            filteredContent.length > 0 ?
              filteredContent.map((item, index) => (
                <>
                  {
                    item.type === 'image' && <Image key={index}
                      data={item}
                      addSchedule={addSchedule}
                      deleteSingleContent={handleDeleteContent} 
                      editContent={onEditContent}
                      />
                  }
                  {
                    item.type === 'video' && <Vedio key={index}
                      data={item}
                      addSchedule={addSchedule}
                      deleteSingleContent={handleDeleteContent}
                      editContent={onEditContent}
                       />
                  }
                  {
                    item.type === 'url' && <Url key={index}
                      data={item}
                      addSchedule={addSchedule}
                      deleteSingleContent={handleDeleteContent}
                      editContent={onEditContent}
                      />
                  }
                </>
              ))
              :
              content.map((item, index) => (
                <>
                  {
                    item.type === 'image' && <Image key={index}
                      data={item}
                      addSchedule={addSchedule}
                      deleteSingleContent={handleDeleteContent} 
                      editContent={onEditContent}
                      />
                  }
                  {
                    item.type === 'video' && <Vedio key={index}
                      data={item}
                      addSchedule={addSchedule}
                      deleteSingleContent={handleDeleteContent} 
                      editContent={onEditContent}
                      />
                  }
                  {
                    item.type === 'url' && <Url key={index}
                      data={item}
                      addSchedule={addSchedule}
                      deleteSingleContent={handleDeleteContent} 
                      editContent={onEditContent}
                      />
                  }
                </>
              ))
          }
        </div>
      </div>

      <Dialog
        sx={{ zIndex: "10000000000000" }}
        open={openSchedule}
        onClose={() => setOpenSchedule(false)}
        maxWidth={"lg"}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "30px",
            // paddingBottom: '18px',
            borderBottom: "3px solid #0d0d0d",
          }}
        >
          <ScheduleSendIcon sx={{ color: "#0d0d0d" }} /> Schedule
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "50vh",
              width: "30vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "97%",
                width: "97%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-date">Start Date</InputLabel>
                  <TextField
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    id={"start-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-date">End Date</InputLabel>
                  <TextField
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    id={"end-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-time">Start Time</InputLabel>
                  <TextField
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    id={"start-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-time">End Time</InputLabel>
                  <TextField
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    id={"end-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                id={"days-id"}
                sx={{
                  minHeight: "20%",
                  margin: "10px 0",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {days.map((d, index) => {
                  return (
                    <>
                      <input
                        onChange={handleDayCheckbox}
                        name={d._id}
                        value={d.day}
                        checked={d.dayChecked ? true : false}
                        type={"checkbox"}
                        id={`day-${index}`}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor={`day-${index}`}
                        style={{
                          cursor: "pointer",
                          borderRadius: "10px",
                          height: "50px",
                          width: "80px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                      >
                        {d.day}
                      </label>
                    </>
                  );
                })}
              </Box>

              <Box
                sx={{
                  minHeight: "20%",
                  margin: "10px 0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                <Button
                  variant={"outlined"}
                  type="button"
                  sx={{
                    width: "100px",
                  }}
                  onClick={cancelSchedule}
                >
                  Cancel
                </Button>
                <Button
                  variant={"contained"}
                  type="button"
                  sx={{
                    width: "100px",
                  }}
                  onClick={saveSchedule}
                >
                  Save
                </Button>
                {isScheduled ? (
                  <>
                    <Button
                      variant="contained"
                      color={"error"}
                      onClick={() => deleteScheduleFromContent(contentID)}
                    >
                      Delete
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <DeleteModel
        open={openDialog}
        onClose={(e) => setOpenDialog(e)}
        onConfirm={confirmDeleteContent}
      />

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={openMediaPop}
        onClose={() => setOpenMediaPop(false)}
        sx={{
          zIndex: '100000000'
        }}
      >
        <DialogTitle style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '3px solid #0d0d0d',
          fontSize: '18px',
          fontWeight: 700
        }}>
          Add Content
        </DialogTitle>
        <DialogContent>
          <div
            className='content-add-media-box'
          >
            <input type="file" hidden id="photo" onChange={fileHandler} />
            <div
              className='add-media-tab'
            >
              <label
                htmlFor="photo"
                className='add-media-label'
              >
                <ImageIcon
                  className='add-media-icon'
                />
                <p className="media-text">Image</p>
              </label>
            </div>
            <div
              className='add-media-tab'
            >
              <label
                htmlFor="photo"
                className='add-media-label'
              >
                <VideoIcon
                  className='add-media-icon'
                />
                <p className="media-text">Video</p>
              </label>
            </div>

            <div className='add-media-tab add-media-tab-flex' style={{
              backgroundColor: isActive ? '#0d0d0d' : '',
              color: isActive ? 'white' : '',
            }}
              onClick={handleClick}
            >
              <UrlIcon
                className='add-media-icon'
                onClick={(handleClick) => setUrlContentForm(!urlContentForm)}
                style={{
                  background: isActive ? 'white' : 'white',
                }}
              />
              <p className="media-text" style={{
                color: isActive ? 'white' : '',
              }}
                onClick={handleClick}
              >
                Url
              </p>
            </div>
          </div>
          {urlContentForm && (
            <div style={{ margin: "20px 0" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="standard"
                  value={urlName}
                  onChange={(e) => setUrlName(e.target.value)}
                  label="Title"
                  fullWidth
                  sx={{
                    label: {
                      color: 'blue',
                      fontSize: '14px',
                      color: '#92959E',
                    }
                  }}
                />
                <TextField
                  variant="standard"
                  value={urlContent}
                  onChange={(e) => setUrlContent(e.target.value)}
                  label="Url Link"
                  fullWidth
                  sx={{
                    label: {
                      color: 'blue',
                      fontSize: '14px',
                      color: '#92959E',
                    }
                  }}
                />
                <button style={{ marginTop: '40px' }} className="btn-save-content" onClick={urlContentSubmit}>
                  Save
                </button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

<Dialog
 maxWidth="sm"
 fullWidth={true} 
open={editContentModel} 
onClose={()=>setEditContentModel(false)}>
  <DialogTitle>Url Edit</DialogTitle>
  <DialogContent>
  <div style={{ margin: "20px 0" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="standard"
                  value={urlName}
                  onChange={(e) => setUrlName(e.target.value)}
                  label="Title"
                  fullWidth
                  sx={{
                    label: {
                      color: 'blue',
                      fontSize: '14px',
                      color: '#92959E',
                    }
                  }}
                />
                <TextField
                  variant="standard"
                  value={urlContent}
                  onChange={(e) => setUrlContent(e.target.value)}
                  label="Url Link"
                  fullWidth
                  sx={{
                    label: {
                      color: 'blue',
                      fontSize: '14px',
                      color: '#92959E',
                    }
                  }}
                />
                <button style={{ marginTop: '40px' }} className="btn-save-content" onClick={urlContentUpdate}>
                  Update
                </button>
              </div>
            </div>
  </DialogContent>
</Dialog>

    </Layout>
  );
}

export default Content;

import { Box } from '@mui/system';
import React from 'react'
import Chart from 'react-apexcharts';
function AreaChart() {
  return (
    <>
      <Box>
        <Chart
          type={'area'}
          // width={800}
          // height={400}
          series={[
            {
              name: 'Daily Sale',
              data: [400, 5000, 2000, 1000, 4000, 500]
            }
          ]}
          options={
            {
              title: {
                text: 'Daily Sales',
                style: { fontSize: 16 }

              },
              colors: ['#0d0d0d'],
              stroke: { width: 1, curve: 'smooth' },
              xaxis: {
                categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
              },
              dataLabels: {
                enabled: false
              },
              responsive: [{
                breakpoint: 992,
                options: {
                  chart: {
                    width: 1100,
                  },
                  legend: {
                    show: false
                  }
                }
              }
              ],
            }
          }

        >
        </Chart>
      </Box>
    </>
  )
}

export default AreaChart
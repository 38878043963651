import { Grid, Table, TableBody, TableHead, TableRow ,TableCell} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Layout from '../../components/layout/Layout'
import './screen.css';
import { styled, alpha } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ScreenTableComponent from '../../components/screenstable/ScreenTableComponent';
import { getClientScreens } from '../../Redux/Actions/screenAction';
const CustomTableCell = styled(TableCell)(({ theme }) => ({
    color: '#15192C',
    fontSize: '14px',
    fontWeight: '700'
  }));
function ClientScreens({id}) {
  const dispatch=useDispatch();
  const {clientScreens}= useSelector(state=>state.Screen)  
  useEffect(()=>{
       dispatch(getClientScreens(id))
    },[id])
  return (
    <>
   
                <Box sx={{minWidth:'100%'}}>
                    <Table>
                       <TableHead>
                           <TableRow style={{ borderBottom: "2px solid #0d0d0d" }}>
                           <CustomTableCell align="center">ID</CustomTableCell>
                    <CustomTableCell align="center">code</CustomTableCell>
                    <CustomTableCell align="center">Name</CustomTableCell>
                    <CustomTableCell align="center">Mac</CustomTableCell>
                    <CustomTableCell align="center">Status</CustomTableCell>
                    <CustomTableCell align="center">Actions</CustomTableCell>
                           </TableRow>
                       </TableHead> 
                       <TableBody>
                           {
                             clientScreens.length===0 ? <><h1>no data</h1></>
                             :
                             clientScreens.map((screens,index)=> <ScreenTableComponent index={index} screen={screens}/>)
                           }
                       </TableBody>
                    </Table>
                </Box>
           
    </>
  )
}

export default ClientScreens
import { api} from "../../api/api";
import {
  GET_PLAYLIST_SUCCESS,
  ADD_CONTENT_VALUE,
  ADD_PLAYLIST_SUCCESS,
  DELETE_CONTENT_VALUE,
  ADD_PLAYLIST_REQUEST,
  DELETE_CONTENT_FROM_PLAYLIST,
  UPDATE_DURATION,
  DELETE_PLAYLIST,
  PLAYLIST_SCREEN_CHECK,
  ADD_SCREEN_TO_PLAYLIST,
  ADD_CONTENT_IN_PLAYLIST,
  GET_PLAYLIST_REQUEST,
  GET_PLAYLIST_FAILED,
  PLAYLIST_FLAG_OFF,
  PLAYLIST_SCHEDULE_SUCCESS,
  PLAYLIST_SCHEDULE_DELETE,
  CLIENT_PLAYLIST_SUCCESS
} from "../constant";

export const playlistFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: PLAYLIST_FLAG_OFF,
      payload: "",
    });
  };
};
export const addPlaylist = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_PLAYLIST_REQUEST,
      payload: "",
    });
    const playlist = await api.post(`/playlist/add`, data);
    console.log(playlist.data);
    if (playlist.status === 201) {
      dispatch({ type: ADD_PLAYLIST_SUCCESS, payload: playlist.data.playlist });
    }
  };
};
export const getPlaylist = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PLAYLIST_REQUEST,
      payload: "",
    });
    const playlist = await api.get(
      `/playlist/getplaylist/${userId}`
    );
    console.log("playlist", playlist.data.playlist);
    if (playlist.status === 200) {
      if (playlist.data.playlist.length > -1) {
        dispatch({
          type: GET_PLAYLIST_SUCCESS,
          payload: playlist.data.playlist,
        });
      } else {
        dispatch({
          type: GET_PLAYLIST_FAILED,
          payload: "",
        });
      }
    }
  };
};
export const addContentInPlaylist = (obj) => {
  return async (dispatch) => {
    console.log(obj)
    const playlist = await api.patch(
      `/playlist/addcontent/${obj.id}`,
      {
        content: obj.value,
      }
    );
    console.log(playlist);
    if (playlist.status) {
      dispatch({
        type: ADD_CONTENT_IN_PLAYLIST,
        payload: playlist.data.playlist,
      });
    }
  };
};
export const playlistScreenCheck = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: PLAYLIST_SCREEN_CHECK,
      payload: obj,
    });
  };
};
export const deleteContentFromPlaylist = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CONTENT_FROM_PLAYLIST,
      payload: obj,
    });
    const updatedPlaylist = await api.patch(
      `/playlist/deletecontentfromplaylist/${obj.playlistId}`,
      { contentId: obj.contentId }
    );
    console.log(updatedPlaylist.data);
  };
};
export const deletePlaylist = (id) => {
  return async (dispatch) => {
    const playlist = await api.get(
      `/playlist/deleteplaylist/${id}`
    );
    if (playlist.status === 201) {
      dispatch({
        type: DELETE_PLAYLIST,
        payload: playlist.data.playlist,
      });
    }
  };
};
export const updateDuration = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_DURATION,
      payload: obj,
    });
    await api.patch(`/playlist/updateduration/${obj.playlistId}`, {
      time: obj.time,
      contentId: obj.contentId,
    });
  };
};
export const updatePlaylist=(pid,cid,obj)=>{
  return async (dispatch)=>{
    const updated=await api.patch(`/playlist/schedule/${pid}/${cid}`,obj);
    console.log('schdule => ',updated)
    if(updated.status===201){
      dispatch({
        type:PLAYLIST_SCHEDULE_SUCCESS,
        payload:updated.data.updated
      })
    }
  }
}
export const deletePlaylistSchedule=(pid,cid)=>{
  return async (dispatch)=>{
    const updated=await api.patch(`/playlist/deletecontentschedule/${pid}/${cid}`);
    console.log('deleted schdule => ',updated)
    if(updated.status===201){
      dispatch({
        type:PLAYLIST_SCHEDULE_DELETE,
        payload:updated.data.updated
      })
    }
  }
}
export const getClientPlaylist=(id)=>{
  return async (dispatch)=>{
    const {status,data}=await api.get(`/playlist/getclientplaylists/${id}`);
    if(status==200){
         dispatch({
           type:CLIENT_PLAYLIST_SUCCESS,
           payload:data.playlist
         })
    }
  }
}
import React, { useEffect } from 'react'
import Layout from '../../components/layout/Layout'
import { Box, Tab, Grid, Typography,Switch } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useParams } from 'react-router-dom';
import Screen from '../screen/Screen';
import ClientScreens from '../screen/ClientScreens';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ListIcon from "@mui/icons-material/List";
import ClientContent from '../content/ClientContent';
import ClientPlaylist from '../playlist/ClientPlaylist'
import { statusOfUser } from '../../Redux/Actions/userAction';
function UserPanel({ user, socketRef }) {
  const dispatch=useDispatch();
  const [value, setValue] = React.useState('1');
  const { id } = useParams();
  const { userInfo,totalScreens,totalContents,totalPlaylists } = useSelector((state) => ({
    userInfo: state.User.users.find(u => u._id == id),
    totalScreens:state.Screen.clientScreens.filter(s=>s?.subuser?._id===id).length,
    totalContents:state.Content.clientContent.filter(c=>c?.subuser._id===id).length,
    totalPlaylists:state.Playlist.clientPlaylist.filter(p=>p.subuser._id===id).length
  }), shallowEqual)
  useEffect(() => {

  }, [])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const onChangeUserStatus=(e)=>{
    const {checked}=e.target;
    if(checked){
      dispatch(statusOfUser(id,'block'));
    }else{
      dispatch(statusOfUser(id,'unblock'));
    }
  }
  return (
    <>
      <Layout title={"User info"} user={user}>
        <Grid container>
          <Box sx={{
            margin: '0 25px',
            display: 'flex',
            width: '100%',
            borderRadius: '16px',
            // padding: '20px',
            alignItems: 'center',
            // marginLeft: 0
          }}>
            <Grid item lg={9} sx={{
              background: 'white',
              // padding: '33px 0',
              borderRadius: '16px',
              mr: 2,
              width: '100%',
              height: '15vh'
            }}>
              <Grid container sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item lg={5}>
                  <Box sx={{ height: '15vh', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Box sx={{ height: '11vh', width: '35%',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <PersonAddIcon
                      sx={{
                        background: "#EDE8FF",
                        padding: "15px",
                        color: "#0d0d0d",
                        borderRadius: "16px"
                      }}
                      fontSize={'large'}
                    />
                    </Box>
                    <Box sx={{ height: '11vh', width: '65%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start' }}>
                      <span>{userInfo.username}</span>
                      <span>{userInfo.email}</span>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={2.3}>
                <Box sx={{ height: '15vh', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                   
                <DesktopMacIcon
                      sx={{
                        background: "#EDE8FF",
                        padding: "15px",
                        color: "#0d0d0d",
                        borderRadius: "16px",
                      }}
                    />
                     <Box pl={1}>
                      <Typography
                        sx={{
                          color: "#92959E",
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                         Screens
                      </Typography>
                      {/* {screenLoading ? (
                        <Box className="minLoader"></Box>
                      ) : ( */}
                        <Typography
                          sx={{
                            color: "#92959E",
                            fontSize: "14px",
                          }}
                        >
                          
                          {totalScreens}
                        </Typography>
                      {/* )} */}
                    </Box>
                  </Box>
                  </Grid>
                <Grid item lg={2.3}>
                <Box sx={{ height: '15vh', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <TextSnippetIcon
                      sx={{
                        background: "#EDE8FF",
                        padding: "15px",
                        color: "#0d0d0d",
                        borderRadius: "16px",
                      }}
                    />
                   {/* <DesktopMacIcon
                         sx={{
                           background: "#EDE8FF",
                           padding: "15px",
                           color: "#0d0d0d",
                           borderRadius: "16px",
                         }}
                       /> */}
                        <Box pl={1}>
                         <Typography
                           sx={{
                             color: "#92959E",
                             fontSize: "13px",
                             fontWeight: 700,
                           }}
                         >
                            Content
                         </Typography>
                         {/* {screenLoading ? (
                           <Box className="minLoader"></Box>
                         ) : ( */}
                           <Typography
                             sx={{
                               color: "#92959E",
                               fontSize: "14px",
                             }}
                           >
                             
                             {totalContents}
                           </Typography>
                         {/* )} */}
                       </Box>
                     </Box>
                </Grid>
                <Grid item lg={2.3}>
                <Box sx={{ height: '15vh', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <ListIcon
                      sx={{
                        background: "#EDE8FF",
                        padding: "15px",
                        color: "#0d0d0d",
                        borderRadius: "16px",
                      }}
                    />
                   {/* <DesktopMacIcon
                         sx={{
                           background: "#EDE8FF",
                           padding: "15px",
                           color: "#0d0d0d",
                           borderRadius: "16px",
                         }}
                       /> */}
                        <Box pl={1}>
                         <Typography
                           sx={{
                             color: "#92959E",
                             fontSize: "13px",
                             fontWeight: 700,
                           }}
                         >
                            Playlist
                         </Typography>
                         {/* {screenLoading ? (
                           <Box className="minLoader"></Box>
                         ) : ( */}
                           <Typography
                             sx={{
                               color: "#92959E",
                               fontSize: "14px",
                             }}
                           >
                            
                             {totalPlaylists}
                           </Typography>
                         {/* )} */}
                       </Box>
                     </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3}>
              <div className="button-controlls flex flex-column">
                {/* <button
                  className="add-screen-btn"
                // onClick={() => setOpenModel(true)}
                >
                  Subscribe
                </button> */}
                {userInfo.blocked ? <><p>De-active</p></> : <p>Active</p>}
                <div className="lower-btn-group flex">
                  {/* <button className="btn-1 main-color">Limit User</button> */}
                 <Switch checked={userInfo?.blocked} onChange={onChangeUserStatus} />
                  {/* <button className="btn-2 main-color">
                    
                  </button> */}
                </div>
              </div>
            </Grid>
          </Box>
        </Grid>
        <Grid container>
          <Box
            sx={{
              margin: '0 25px',
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              borderRadius: '16px',
              //alignItems: 'center',
              mt: 4
            }}
          >

            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Screens" style={{ borderRadius: '10px', minWidth: '27vw', background: value == '1' ? '#0D0D0D' : 'none', color: value == '1' ? '#ffffff' : '#000000' }} value="1" />
                  <Tab label="Content" style={{ borderRadius: '10px', minWidth: '26vw', background: value == '2' ? '#0D0D0D' : 'none', color: value == '2' ? '#ffffff' : '#000000' }} value="2" />
                  <Tab label="Playlist" style={{ borderRadius: '10px', minWidth: '26vw', background: value == '3' ? '#0D0D0D' : 'none', color: value == '3' ? '#ffffff' : '#000000' }} value="3" />
                </TabList>
              </Box>
              <TabPanel value={'1'}>
                <ClientScreens id={id} />
              </TabPanel>
              <TabPanel value={'2'}>
                <ClientContent id={id}/>
              </TabPanel>
              <TabPanel value={'3'}>
                <ClientPlaylist id={id}/>
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>

      </Layout>
    </>
  )
}

export default UserPanel
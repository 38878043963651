import { api } from "../../api/api";
import {
  ADD_CONTENT,
  GET_CONTENT_REQUEST,
  GET_CONTENT_SUCCESS,
  DELETE_CONTENT_REQUEST,
  CHECK_ALL_CONTENT,
  UNCHECK_ALL_CONTENT,
  CHECK_ONE_CONTENT,
  UNCHECK_ONE_CONTENT,
  DELETE_ALL_CONTENT,
  ADD_CONTENT_REQUEST,
  DELETE_CONTENT_REQUEST_SUCCESS,
  GET_CONTENT_FAILED,
  CONTENT_FLAG_OFF,
  CONTENT_SCHEDULE_DELETE,
  CONTENT_SCHEDULE_SUCCESS,
  CLIENT_CONTENT_SUCCESS,
  CONTENT_UPDATED
} from "../constant";

export const contentFlagOff=()=>{
  return async (dispatch)=>{
    dispatch({
      type:CONTENT_FLAG_OFF,
      payload:''
    })
  }
}
export const addContent = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_CONTENT_REQUEST,
      payload: "",
    });
    const content = await api.post(`/content/upload`, obj);
    if (content.status === 201) {
      dispatch({ type: ADD_CONTENT, payload: content.data.content });
    }
  };
};
export const getContent = (id) => {
  return async (dispatch) => {
    console.log('from get content=>',id)
    dispatch({
      type: GET_CONTENT_REQUEST,
      payload: "",
    });
    const content = await api.get(`/content/getcontent/${id}`);
    console.log(content)
    if (content.status === 200) {
      if(content.data.content.length > -1){
        dispatch({
          type: GET_CONTENT_SUCCESS,
          payload: content.data.content,
        });
      }
      else{
        dispatch({
          type:GET_CONTENT_FAILED,
          payload:''
        })
      }
    }
  };
};
export const deleteContent = (id) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CONTENT_REQUEST,
      payload: "",
    });
    dispatch({
      type: DELETE_CONTENT_REQUEST_SUCCESS,
      payload: id,
    });
    const content = await api.get(`/content/delete/${id}`);
    // if (content.status === 201) {

    // }
  };
};
export const checkAllContent = () => {
  return async (dispatch) => {
    dispatch({
      type: CHECK_ALL_CONTENT,
      payload: "",
    });
  };
};
export const unCheckAllContent = () => {
  return async (dispatch) => {
    dispatch({
      type: UNCHECK_ALL_CONTENT,
      payload: "",
    });
  };
};
export const checkOneContent = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CHECK_ONE_CONTENT,
      payload: id,
    });
  };
};
export const unCheckOneContent = (id) => {
  return async (dispatch) => {
    dispatch({
      type: UNCHECK_ONE_CONTENT,
      payload: id,
    });
  };
};
export const deleteAllContent = (updatedArr, selectedRows) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ALL_CONTENT,
      payload: updatedArr,
    });
    await api.post(`/content/multipledelete`, { selectedRows });
  };
};
export const addContentSchedule=(id,obj)=>{
  return async (dispatch)=>{
    const updated=await api.patch(`/content/schedule/${id}`,obj);
    console.log('schdule => ',updated)
    if(updated.status===201){
      dispatch({
        type:CONTENT_SCHEDULE_SUCCESS,
        payload:updated.data.updated
      })
    }
  }
}
export const deleteContentSchedule=(id)=>{
  return async (dispatch)=>{
    console.log('from content delete=>',id)
    const updated=await api.patch(`/content/deletecontentschedule/${id}`);
    console.log('deleted schdule => ',updated)
    if(updated.status===201){
      dispatch({
        type:CONTENT_SCHEDULE_DELETE,
        payload:updated.data.updated
      })
    }
  }
}
export const getClientContent=(id)=>{
  return async (dispatch)=>{
    const {status,data}=await api.get(`/content/getclientcontents/${id}`);
     if(status==200){
       dispatch({
         type:CLIENT_CONTENT_SUCCESS,
         payload:data.content
       })
     }
  }
}
export const contentUpdate=(id,objForUpdateContent)=>{
  return async (dispatch)=>{
    console.log(objForUpdateContent)
    const {status,data}=await api.patch(`/content/updatecontent/${id}`,objForUpdateContent)
    if(status===201){
      dispatch({
        type:CONTENT_UPDATED,
        payload:data.content
      })
    }
  }
}